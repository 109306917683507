<template>
    <div>
        员工资料
    </div>
</template>

<script>
export default {
    name: "PerEmp"
}
</script>

<style scoped>

</style>